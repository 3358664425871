<script>
export default {
  props: {
    currentPrice: {
      type: Number,
    },
    originalPrice: {
      type: Number,
    },
  },
};
</script>

<template>
  <div
    class="product-card-price"
    :class="{ 'product-card-price--discounted': originalPrice }"
  >
    {{ $formatPrice(currentPrice) }}
    <span v-if="originalPrice" class="product-card-price__original">{{
      $formatPrice(originalPrice)
    }}</span>
  </div>
</template>

<style>
.product-card-price,
.product-card-price__original {
  font-size: 12px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-card-price__original {
  position: relative;
  text-decoration: line-through;
}

.product-card-price.product-card-price--discounted {
  color: var(--color-discount);
}

.product-card-price.product-card-price--discounted {
  color: var(--color-discount);
}

@media (--tabletAndDesktop) {
  .product-card-price {
    color: #1a1a1a;
  }

  .product-card-price,
  .product-card-price__original {
    font-size: 14px;
  }
}
</style>
