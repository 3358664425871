<script>
import { computed, ref } from 'vue';
import { useGA4 } from '@distancify-storefront/tracking-gtm';
import ColorSwatch from '../ColorSwatch.vue';
import ProductCardMedia from './ProductCardMedia.vue';
import ProductCardPrice from './ProductCardPrice.vue';
import HeartIcon from './HeartIcon.vue';
import useCompareProducts from '@/composables/useCompareProducts';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useSizeDisplay from '@/composables/useSizeDisplay';

const ga4 = useGA4({
  useLegacyEnhancedECommerce: true,
});

const maxVisibleColors = 5;

export default {
  components: {
    ColorSwatch,
    ProductCardMedia,
    ProductCardPrice,
    HeartIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    hideRelatedArticles: {
      type: Boolean,
      default: false,
    },
    sizeFilter: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    compareProduct: false,
    isMounted: false,
    intersectionObserver: null,
    isContentVisible: false,
  }),
  setup(props) {
    const { websiteText } = useWebsiteTexts();
    const compareProducts = useCompareProducts();
    const { getSizeName } = useSizeDisplay();
    const showAllSizes = ref(false);
    const selectedColor = ref(null);
    const allSizes = computed(() => {
      let sizes = props.product?.sizes;
      
      if (selectedColor.value) {
        sizes = selectedColor.value.sizes;
      }

      return sizes?.map((s) => ({ ...s, caption: getSizeName(s.caption) })) || [];
    });

    const sizeRange = computed(() => {
      if (!allSizes.value.length) {
        return '';
      }
      if (allSizes.value.length == 1) {
        return `${websiteText('productcard__size_range').value || 'productcard__size_range'} ${
          allSizes.value[0].caption
        }`;
      }
      return `${websiteText('productcard__size_range').value || 'productcard__size_range'} ${
        allSizes.value[0].caption
      }-${allSizes.value[allSizes.value.length - 1].caption}`;
    });

    function onMediaMouseOver() {
      if (allSizes.value.length > 1) {
        showAllSizes.value = true;
      }
    }

    function onMediaMouseLeave() {
      showAllSizes.value = false;
    }

    return {
      websiteText,
      compareProducts,
      selectedColor,
      allSizes,
      showAllSizes,
      sizeRange,
      onMediaMouseOver,
      onMediaMouseLeave,
    };
  },
  watch: {
    isHearted(active) {
      if (active) {
        this.compareProducts.add(this.product.articleNumber);
      } else {
        this.compareProducts.remove(this.product.articleNumber);
      }
    },
  },
  computed: {
    isHearted() {
      return this.compareProducts.products.value.indexOf(this.product.articleNumber) !== -1;
    },
    currentVariant() {
      const variants = this.product?.variants || [];
      for (const variant of variants) {
        if (variant.articleNumber.toLowerCase() === this.product.articleNumber.toLowerCase()) {
          return variant;
        }
      }
      return null;
    },
    variantQueryParams() {
      let urlSuffix = '';
      if (this.currentVariant) {
        const suffixParts = [
          {
            key: 'size',
            value: this.currentVariant?.size || '',
          },
          {
            key: 'color',
            value: (this.currentVariant?.color || []).join(','),
          },
        ].filter((q) => q.value);
        if (suffixParts.length) {
          urlSuffix = suffixParts.reduce((p, { key, value }) => `${p}${key}=${value}&`, '?').slice(0, -1);
        }
      }
      return urlSuffix;
    },
    productUrl() {
      if (this.selectedColor) {
        return this.selectedColor.url + this.variantQueryParams;
      }

      return (this.product?.relativeURL || this.product?.url || this.product.href) + this.variantQueryParams || '';
    },
    productTag() {
      return this.product?.productCardTag?.name || '';
    },
    productName() {
      return this.product?.productName || this.product?.name || '';
    },
    currentPrice() {
      return this.product.campaignPrice ? this.product.campaignPrice : this.product.price;
    },
    originalPrice() {
      if (this.product.campaignPrice && this.product.price > this.product.campaignPrice) {
        return this.product.price;
      }
      return 0;
    },
    articleNumber() {
      return this.product?.articleNumber || '';
    },
    modelNumber() {
      return this.product?.modelNumber || '';
    },
    colorName() {
      return this.product?.colorName || '';
    },
    currency() {
      return this.$cart?.currency?.id;
    },
    locale() {
      return this.$channel?.locale;
    },
    decimals() {
      return this.$cart?.currency?.currencyDecimals || 0;
    },
    images() {
      const height = this.$refs?.imageContainer?.getBoundingClientRect()?.height ?? 450;
      if (this.product.imageIds?.length) {
        return this.product.imageIds.map((i) => this.$toLitiumMediaUrl(i, { maxHeight: height }));
      }
      return [this.$toLitiumMediaUrl(this.product.image, { maxHeight: height })];
    },
    selectedColorImage() {
      const imageId = this.selectedColor?.imageId;
      if (!imageId) return null;
      const height = this.$refs?.imageContainer?.getBoundingClientRect()?.height ?? 450;

      return this.$toLitiumMediaUrl(imageId, { maxHeight: height });
    },
    variants() {
      return this.product?.variants || [];
    },
    otherVariants() {
      return this.variants.filter((v) => v.articleNumber != this.articleNumber);
    },
    colors() {
      const colorMap = this.$metaProduct?.fieldsJson?.ProductCardColorMap;
      if (!colorMap || !this.product.colorOptions) return [];

      return this.product.colorOptions
        .filter((op) => {
          if (!this.sizeFilter || !this.sizeFilter.length) return true;
          return op.sizes.find((s) => this.sizeFilter.includes(s.caption) && s.inStock) || false;
        }).reduce((acc, op) => {
          const cssColor = colorMap[op.colorCode];
          if (!cssColor) return acc;

          const colorOption = {
            code: op.colorCode,
            cssColor,
            active: this.product.colorCode === op.colorCode,
            url: op.url,
            imageId: op.imageId,
            sizes: op.sizes,
          };

          if (this.product.colorCode === op.colorCode && acc.length > 4) {
            acc.splice(4, 0, colorOption);
          } else {
            acc.push(colorOption);
          }

          return acc;
        }, []);
    },
    extraColors() {
      return this.colors.length - maxVisibleColors;
    },
    category() {
      return this.$page.dataJson?.category;
    },
    hidePrices() {
      const ecommerceFeaturesEnabled = this.$cart?.ecommerceFeaturesEnabled || false;
      if (!ecommerceFeaturesEnabled) {
        return true;
      }
      const hidePrices = this.$channel?.hidePricesForAnonymousUsers || false;
      return hidePrices ? !this.$user?.isAuthenticated : false;
    },
  },
  methods: {
    pushSelectItemEvent() {
      const item = {
        item_id: this.modelNumber,
        item_name: this.productName,
        item_color_id: this.articleNumber,
        item_color: this.colorName,
      };

      if (this.category) {
        item.item_list_id = `category_${this.category.systemId}`;
        item.item_list_name = `Category: ${this.category.googleAnalyticsName}`;
      }

      ga4.selectItem(item);
    },
    toggleHeart() {
      const baseProductNumber = this.product.articleNumber;
      if (this.isHearted) {
        this.compareProducts.remove(baseProductNumber);
      } else {
        this.compareProducts.add(baseProductNumber);
      }
    },
    selectColorOption(color) {
      if (this.product.colorCode !== color.code) {
        this.selectedColor = color;
      } else {
        this.selectedColor = null;
      }
    },
  },
  mounted() {
    this.isMounted = true;

    const options = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.1,
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isContentVisible = true;
        } else {
          this.isContentVisible = false;
        }
      });
    }, options);

    this.intersectionObserver.observe(this.$refs.productCardElRef.$el);
  },
  beforeUnmount() {
    this.intersectionObserver?.disconnect();
  },
};
</script>

<template>
  <router-link
    :to="productUrl"
    class="product-card"
    ref="productCardElRef"
    @click="pushSelectItemEvent()">
    <div class="product-card__content">
      <div class="product-card__product-media">
        <div
          class="product-card__image-container"
          @mouseover="onMediaMouseOver"
          @mouseleave="onMediaMouseLeave">
          <div v-if="selectedColorImage" class="product-card__selected-color-overlay-background"></div>
          <img v-if="selectedColorImage" :src="selectedColorImage" class="product-card__selected-color-overlay"/>
          <div v-if="selectedColorImage" class="product-card__selected-color-overlay-shadow"></div>
          <ProductCardMedia :images="images" />
        </div>
      </div>
      <div v-if="isContentVisible" class="product-card__info">
        <template v-if="!showAllSizes">
          <heart-icon
            class="product-cart__heart"
            :active="isHearted"
            @click.stop.prevent="toggleHeart"></heart-icon>
          <div
            v-if="productTag"
            class="product-card__product-tag">
            {{ productTag }}
          </div>
          <h2 class="product-card__name">{{ productName }}</h2>
          <ProductCardPrice
            :originalPrice="originalPrice"
            :currentPrice="currentPrice"
            v-if="!hidePrices" />
        </template>
        <template v-if="showAllSizes">
          <div class="product-card__sizes-caption">
            {{ websiteText('productcard__sizes_caption').value || 'productcard__sizes_caption' }}
          </div>
          <div class="product-card__sizes">
            <div
              class="product-card__size"
              :class="{ 'product-card__size--out-of-stock': !size.inStock }"
              v-for="size in allSizes"
              :key="size.caption">
              <span>{{ size.caption }}</span>
            </div>
          </div>
        </template>
        <div :class="['product-card__colors', { 'product-card__less-margin': showAllSizes }]" @click.prevent.stop="">
          <ColorSwatch
            v-for="color in colors.slice(0,5)"
            :key="color.cssColor"
            :color="color.cssColor"
            :active="!selectedColor ? color.active : color.code === selectedColor.code"
            @click.prevent.stop="selectColorOption(color)"
            class="product-card__color-swatch"
          />
          <div v-if="extraColors > 0" class="product-card__colors-extra">
            {{ `+${extraColors}` }}
          </div>
        </div>
        <ProductCardPrice
          :originalPrice="originalPrice"
          :currentPrice="currentPrice"
          v-if="!hidePrices && showAllSizes" />
        <div
          :class="['product-card__size-range', { 'product-card__size-range--extra-margin': !productTag }]"
          @click.prevent.stop=""
          v-if="allSizes.length > 0 && !showAllSizes">
          {{ sizeRange }}
        </div>
      </div>
      <div
        class="product-card__info--shell"
        v-if="!isContentVisible"></div>
    </div>
  </router-link>
</template>

<style>
  .product-card {
    cursor: pointer;
    text-decoration: none;
    background-color: white;
    user-select: none;
  }

  .product-card__image-container {
    overflow: hidden;
    position: relative;
    background-color: white;
  }

  .product-card__selected-color-overlay-background {
    background-color: white;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .product-card__selected-color-overlay {
    position: absolute;
    height: 100%;
    z-index: 2;
    object-fit: contain;
    object-position: center;
  }
  
  .product-card__selected-color-overlay-shadow {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: var(--box-shadow-overlay);
    mix-blend-mode: darken;

  }

  .product-card__product-tag {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 1.2px;
    text-overflow: ellipsis;
    overflow: visible;
    text-transform: uppercase;
    color: var(--color-text-standard);
    font-family: var(--font-proxima-nova-regular);
  }

  .product-card__sizes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 2px 0;
  }

  .product-card__size {
    font-size: 14px;
    font-family: var(--font-proxima-nova-regular);
    color: var(--color-neutral-100);
  }

  .product-card__sizes-caption,
  .product-card__size-range {
    font-size: 14px;
    font-family: var(--font-proxima-nova-light);
    color: var(--color-neutral-100);
    line-height: 20px;
  }

  .product-card__size-range {
    cursor: default;
  }

  .product-card__size--out-of-stock {
    color: var(--color-text-light);
  }

  .product-card__colors {
    margin-top: 15px;
    height: 32px;
    display: flex;
    cursor: default;
  }

  .product-card__colors-extra {
    height: 13px;
    line-height: 13px;
    color: var(--color-text-standard);
    font-size: 12px;
  }

  .product-card__less-margin {
    margin-top: 9px;
  }

  .product-card__size-range--extra-margin {
    margin-bottom: 20px;
  }

  .product-card__color-swatch {
    padding-left: 6px;
    padding-right: 6px;
  }

  .product-card__color-swatch:first-child {
    padding-left: 0;
  }

  .product-card__image-incoming {
    opacity: 0.5;
  }

  .product-card__info {
    padding: 10px 0;
    background-color: white;
    line-height: 18px;
  }

  .product-card__info--shell {
    height: 134px;
  }

  .product-card__name {
    font-size: 12px;
    color: #1a1a1a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: var(--font-proxima-nova-bold);
    margin: 0;
    line-height: 18px;
  }

  .product-cart__heart {
    float: right;
    padding: 0 2px 0 0;
  }

  @media (--tabletAndDesktop) {
    .product-card__info--shell {
      height: 138px;
    }

    .product-card__info {
      padding: 12px 0 10px;
    }

    .product-card__product-tag {
      font-size: 12px;
      padding-bottom: 2px;
    }

    .product-card__name {
      font-size: 14px;
    }

    .product-card__colors {
      height: 33px;
    }

    .product-card__color-swatch {
      padding-left: 4px;
      padding-right: 4px;
    }

    .product-card__color-swatch:first-child {
      padding-left: 0;
    }

    .product-card__colors-extra {
      height: 15px;
      line-height: 15px;
      font-size: 14px;
    }
  }
</style>
