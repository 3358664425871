import { inject } from 'vue';
import { channelKey } from '@/keys';

export default function () {
  const channel = inject(channelKey);

  return {
    getSizeName: (size: string) => channel?.value.sizeNameOverrides?.[size] || size,
  };
}
