import { inject, ref, ssrContextKey } from 'vue';
import { Emitter } from 'mitt';
import { invokeKey, pageKey, routeKey } from '@drapejs/core';
import { replaceTokensKey, emitterKey, cartKey } from '@/keys';

export default function () {
  return {
    invoke: inject(invokeKey, () => Promise.resolve()),
    page: inject(pageKey, ref()),
    ssrContext: inject(ssrContextKey, undefined),
    route: inject(routeKey, <any>{}),
    replaceTokens: inject(replaceTokensKey, () => ''),
    mitt: inject(emitterKey, <Emitter<any>>(<any>{})),
    cart: inject(cartKey, ref(null)),
  };
}
