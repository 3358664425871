<script>
export default {
  props: {
    expanded: {
      type: Boolean,
    },
  },
  computed: {
    direction() {
      return this.expanded ? 'up' : 'down';
    },
    rotation() {
      switch (this.direction) {
        case 'right':
          return -90;
        case 'left':
          return 90;
        case 'up':
          return -180;
        case 'down':
          return 0;
      }
    },
    style() {
      return {
        transform: `translateY(-70%) rotate(${this.rotation}deg)`,
      };
    },
  },
};
</script>

<template>
  <span class="arrow-expandable">
    <svg
      :style="style"
      class="arrow"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1213_23209)">
        <path
          d="M15.5 4.87401L8.35333 12.02C8.30696 12.0665 8.25188 12.1033 8.19125 12.1284C8.13062 12.1536 8.06563 12.1665 8 12.1665C7.93437 12.1665 7.86938 12.1536 7.80875 12.1284C7.74812 12.1033 7.69304 12.0665 7.64667 12.02L0.5 4.87401"
          class="stroke"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1213_23209">
          <rect width="16" height="16" class="fill" />
        </clipPath>
      </defs>
    </svg>
  </span>
</template>

<style>
.arrow-expandable {
  height: 21px;
  width: 20px;
  margin-left: 0.5rem;
  display: inline-block;
}

.arrow {
  position: relative;
  top: 50%;
  width: inherit;
  height: inherit;
}

.fill {
  fill: white;
}

.stroke {
  stroke: white;
}
</style>
