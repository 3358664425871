<script>
import { registerModalKey, unregisterModalKey } from "@/composables/useModals";

let stackOrder = 120;
let keyIndex = 0;

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    closeOnMaskClick: {
      type: Boolean,
      default: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    containerClass: {
      type: [String, Object, Array],
      default: null,
    },
    modalClass: {
      type: [String, Object, Array],
      default: null,
    },
  },
  inject: {
    registerModal: registerModalKey,
    unregisterModal: unregisterModalKey,
  },
  data() {
    keyIndex += 1;
    return {
      modalKey: `drawer-${keyIndex}`,
      zIndex: "120",
      isMounted: false,
    };
  },
  watch: {
    show(newValue) {
      this.internalIsOpen = newValue;
    },
    modelValue(newValue) {
      if (newValue) {
        stackOrder += 1;
        this.zIndex = stackOrder;
        this.registerModal(this.modalKey);
      } else {
        stackOrder -= 1;
        this.unregisterModal(this.modalKey);
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.registerModal(this.modalKey);
    }
    this.isMounted = true;
  },
  beforeUnmount() {
    if (this.modelValue) {
      this.unregisterModal(this.modalKey);
    }
  },
  computed: {
    computedClass() {
      let classVar = this.modalClass ? this.modalClass : "";

      if (this.modelValue) {
        classVar += " drawer--shown";
      }

      return classVar;
    },
  },
  emits: ["update:modelValue"],
  methods: {
    open() {
      this.$emit("update:modelValue", true);
    },
    closeModal() {
      this.$emit("update:modelValue", false);
    },
    onMaskClick(ev) {
      if (this.$refs.cover !== ev.target) return;

      if (this.closeOnMaskClick) {
        this.closeModal();
      }
    },
  },
};
</script>

<template>
  <template v-if="isMounted">
    <teleport to="#drawer-target">
      <div
        class="drawer"
        :style="{ 'z-index': zIndex }"
        :class="computedClass"
        @mousedown="onMaskClick"
        @touchstart="onMaskClick"
      >
        <div class="drawer__cover" ref="cover" />
        <div class="drawer__container" :class="containerClass" @click.stop>
          <slot v-if="modelValue" />

          <div
            v-if="showCloseIcon"
            class="drawer__close"
            data-cy="modal-close"
            @click="closeModal()"
          >
            <slot name="close">
              <div class="drawer__close-btn">&times;</div>
            </slot>
          </div>
        </div>
      </div>
    </teleport>
  </template>
</template>

<style>
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.drawer--shown {
  pointer-events: all;
}

.drawer__cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: opacity 0.15s ease;
  background-color: #000;
  opacity: 0;
}

.drawer--shown .drawer__cover {
  opacity: 0.2;
}

.drawer__container {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  padding: 0;
  transition: transform 0.15s ease;
  transform: translateX(100%);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.drawer--shown .drawer__container {
  transform: translateX(0%);
}

@media (--phone) {
  .drawer__container {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.drawer__close {
  position: absolute;
  font-size: 35px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--color-neutral-00);
  top: -4px;
  width: 40px;
  height: 40px;
  right: 0px;

  @media (min-width: 768px) {
    right: 25px;
  }
}

.drawer__close-btn {
  margin-top: 2px;
  margin-right: 2px;
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
